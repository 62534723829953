import "./App.css";
import { Landing } from "./pages/landing/Landing";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { useEffect } from "react";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyBMoakG7kbEpomd8_LTH_LjBzoeqp_B_So",
  authDomain: "wildster-hiring.firebaseapp.com",
  projectId: "wildster-hiring",
  storageBucket: "wildster-hiring.appspot.com",
  messagingSenderId: "396667615980",
  appId: "1:396667615980:web:9d391337661d26dfe943c9",
  measurementId: "G-0S5DG9PJL3",
};

const App = () => {
  useEffect(() => {
    const app = initializeApp(firebaseConfig);
    const analytics = getAnalytics(app);
    const storage = getStorage(app);

    global.wild = {
      storage,
      analytics,
    };
  }, []);

  return <Landing />;
};

export default App;
