import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import "./Landing.css";
import { ReactMediaRecorder } from "react-media-recorder";
import { VideoPreview } from "../../components/header/video-preview/preview";

import logdna from "@logdna/browser";

const LOGDNA_INGESTION_KEY = "284eaa7098eb1f6309cf7c4d34896957";

logdna.init(LOGDNA_INGESTION_KEY);

logdna.addContext({
  // Context is appended to the metadata of each message sent to LogDNA
  // Add any custom meta data such as:
  version: "v1.0.0",
  env: "prescreen",
});

// import { v4 as uuidv4 } from "uuid";

export const Landing = () => {
  const [position, setPosition] = useState(1);
  const [recordVideo, setRecordVideo] = useState(true);
  const [submitted, setSubmitted] = useState(false);
  const [resumeUploadProgress, setResumeUploadProgress] = useState(0);
  const [videoUploadProgress, setVideoUploadProgress] = useState(0);
  const [videoBlob, setVideoBlob] = useState(null);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");

  const {
    acceptedFiles: resumeFile,
    getRootProps: getResumeRootProps,
    getInputProps: getResumeInputProps,
  } = useDropzone({
    accept: {
      "application/pdf": [".pdf"],
      "application/msword": [],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [],
    },
  });

  const {
    acceptedFiles: videoFile,
    getRootProps: getVideoRootProps,
    getInputProps: getVideoInputProps,
  } = useDropzone({
    accept: {
      "video/*": [".mp4"],
    },
  });

  const uploadFile = async (username, name, type, data, updateFn) => {
    try {
      const res = await fetch(
        `https://prescreen.wildster.com/api?name=${
          username.replace(/ /g, "-") + name
        }&type=${type}`
      ).then((res) => res.json());

      const formData = new FormData();

      Object.keys(res.fields).forEach((key) => {
        formData.append(key, res.fields[key]);
      });

      formData.append("file", data);

      return new Promise((resolve) => {
        axios
          .request({
            method: "post",
            url: res.url,
            data: formData,
            onUploadProgress: (p) => {
              updateFn((p.loaded * 100) / p.total);
            },
          })
          .then((data) => {
            console.log({ data });
            resolve(res.fields.Key);
          });
      });
    } catch (e) {
      logdna.error(e);
      window.alert(
        "Oops! We can't upload your files. Please try again in some time."
      );
    }
  };

  const startUpload = async () => {
    const basicInfo = {
      firstName,
      lastName,
      email,
    };

    const resume = resumeFile[0];
    const video = recordVideo ? videoBlob : videoFile[0];

    if (!resume || !video) {
      return;
    }

    logdna.addContext({
      // Context is appended to the metadata of each message sent to LogDNA
      // Add any custom meta data such as:
      version: "v1.0.0",
      env: "prescreen",
      user: basicInfo,
    });

    const p1 = uploadFile(
      `${basicInfo.firstName}-${basicInfo.lastName}`,
      resume.name,
      "resume",
      resume,
      setResumeUploadProgress
    );
    const p2 = uploadFile(
      `${basicInfo.firstName}-${basicInfo.lastName}`,
      video.name || "video.mp4",
      "video",
      video,
      setVideoUploadProgress
    );

    const [resumeUrl, videoUrl] = await Promise.all([p1, p2]);

    let params = new URLSearchParams(window.location.search);

    const item = {
      ...basicInfo,
      resumeUrl,
      videoUrl,
      role: params.get("role"),
    };

    try {
      await fetch("https://prescreen.wildster.com/email", {
        method: "POST",
        body: JSON.stringify(item),
      });
    } catch (e) {
      logdna.error(e);
    }

    setPosition(7);
  };

  useEffect(() => {
    if (submitted) {
      startUpload();
    }
  }, [submitted]);

  const onNewVideo = (uri, blob) => {
    // console.log(uri, blob);
    setVideoBlob(blob);
  };

  return (
    <div className="container">
      <div className="left">
        <img
          className="app-logo"
          alt="logo"
          src="https://wildster-dev-bucket.sfo3.digitaloceanspaces.com/well-known/new-logo.png"
        />
      </div>
      <div className="right">
        <div className="right-scroll">
          <div
            className="panes"
            style={{
              transform: `translateY(-${((position - 1) * 100) / 7}%)`,
            }}
          >
            <div className="pane first">
              <div className="content">
                <h2>Wildster Prescreen Interview</h2>
                <div style={{ height: "15px" }}></div>
                <p className="sub-title">
                  In this prescreen interview, you will record a 2 minutes (or
                  less) video introducing yourself so we can see your
                  communication skills.
                </p>
                <div style={{ height: "30px" }}></div>
                <div className="right-align">
                  <button onClick={() => setPosition(2)}>Let's Begin</button>
                </div>
              </div>
            </div>
            <div className="pane">
              <div className="content">
                <h4>Hello, what's your name?</h4>
                <div style={{ height: "30px" }}></div>
                <input
                  type="text"
                  placeholder="First Name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                ></input>
                <div style={{ height: "12px" }}></div>
                <input
                  type="text"
                  placeholder="Last Name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                ></input>
                <div style={{ height: "30px" }}></div>
                <div className="right-align">
                  <button className="back" onClick={() => setPosition(1)}>
                    Back
                  </button>
                  <button
                    disabled={!firstName || !lastName}
                    onClick={() => setPosition(3)}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
            <div className="pane">
              <div className="content">
                <h4>How can we reach you?</h4>
                <div style={{ height: "30px" }}></div>
                <input
                  type="text"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                ></input>
                <div style={{ height: "30px" }}></div>
                <div className="right-align">
                  <button className="back" onClick={() => setPosition(2)}>
                    Back
                  </button>
                  <button disabled={!email} onClick={() => setPosition(4)}>
                    Next
                  </button>
                </div>
              </div>
            </div>

            <div className="pane">
              <div className="content">
                <h4>Upload your resume</h4>
                <div style={{ height: "30px" }}></div>
                <div {...getResumeRootProps({ className: "dropzone" })}>
                  <input {...getResumeInputProps()} />
                  <p>
                    {resumeFile.length === 0
                      ? "Drag 'n' drop some files here, or click to select files"
                      : resumeFile[0].name}
                  </p>
                </div>

                <div style={{ height: "30px" }}></div>
                <div className="right-align">
                  <button className="back" onClick={() => setPosition(3)}>
                    Back
                  </button>
                  <button
                    disabled={resumeFile.length === 0}
                    onClick={() => setPosition(5)}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>

            <div className="pane">
              <div className="content">
                <h4>Record video</h4>
                <p className="sub-title-small">
                  In 2 minutes or less, tell us a little about yourself
                </p>
                <div style={{ height: "30px" }}></div>
                <div
                  className={
                    recordVideo ? "video-recorder" : "video-recorder hidden"
                  }
                >
                  <ReactMediaRecorder
                    video
                    audio
                    onStop={onNewVideo}
                    render={({
                      status,
                      startRecording,
                      stopRecording,
                      mediaBlobUrl,
                      previewStream,
                    }) => (
                      <div>
                        <div className="preview-container">
                          <video src={mediaBlobUrl} controls />
                          {(status === "recording" ||
                            status === "acquiring_media") && (
                            <div className="preview">
                              <VideoPreview stream={previewStream} />
                            </div>
                          )}
                        </div>

                        <div className="center-align">
                          {!(
                            status === "recording" ||
                            status === "acquiring_media"
                          ) && (
                            <button className="inline" onClick={startRecording}>
                              Start Recording
                            </button>
                          )}
                          {(status === "recording" ||
                            status === "acquiring_media") && (
                            <button
                              className="inline red"
                              onClick={stopRecording}
                            >
                              Stop Recording
                            </button>
                          )}
                        </div>
                      </div>
                    )}
                  />
                </div>
                {!recordVideo && (
                  <div {...getVideoRootProps({ className: "dropzone" })}>
                    <input {...getVideoInputProps()} />
                    <p>
                      {videoFile.length === 0
                        ? "Drag 'n' drop some files here, or click to select files"
                        : videoFile[0].name}
                    </p>
                  </div>
                )}
                <div style={{ height: "30px" }}></div>
                <div className="space-between">
                  <button
                    className="inline"
                    onClick={() => setRecordVideo(!recordVideo)}
                  >
                    {recordVideo
                      ? " Or, upload a video"
                      : " Or, record a video"}
                  </button>

                  <div className="right-align">
                    <button className="back" onClick={() => setPosition(4)}>
                      Back
                    </button>
                    <button
                      disabled={
                        recordVideo
                          ? !videoBlob || videoBlob.size === 0
                          : videoFile.length === 0
                      }
                      onClick={() => setPosition(6)}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="pane">
              <div className="content">
                <h2>Almost done</h2>
                <div style={{ height: "15px" }}></div>
                <p className="sub-title">
                  {submitted
                    ? "Hang on.. we are uploading your files, this will not take long"
                    : "Click the button below to submit your video."}
                </p>
                <div style={{ height: "60px" }}></div>

                {submitted && (
                  <div>
                    <p className="label">Resume</p>
                    <div className="progress-container">
                      <div
                        className="progress"
                        style={{ width: resumeUploadProgress + "%" }}
                      ></div>
                    </div>
                    <div style={{ height: "20px" }}></div>
                    <p className="label">Video</p>{" "}
                    <div className="progress-container">
                      <div
                        className="progress"
                        style={{ width: videoUploadProgress + "%" }}
                      ></div>
                    </div>
                  </div>
                )}

                {!submitted && (
                  <div className="right-align">
                    <button className="back" onClick={() => setPosition(5)}>
                      Back
                    </button>
                    <button
                      onClick={() => {
                        setSubmitted(true);
                      }}
                    >
                      Upload Files & Submit
                    </button>
                  </div>
                )}
              </div>
            </div>

            <div className="pane">
              <div className="content">
                <h2>All done!</h2>
                <div style={{ height: "15px" }}></div>
                <p className="sub-title">
                  A member of the Wildster HR team will contact you within 24
                  hours.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
